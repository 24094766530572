import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

import type { Dispatcher } from '@/types/dispatcher';

interface UseDesktopNavigation {
  dropdownRef: RefObject<HTMLLIElement>;
  isOpen: boolean;
  isSearchOpen: boolean;
  searchIconRef: RefObject<HTMLButtonElement>;
  searchInputRef: RefObject<HTMLInputElement>;
  setIsOpen: Dispatcher<boolean>;
  setIsSearchOpen: Dispatcher<boolean>;
}

export const useDesktopNavigation = (): UseDesktopNavigation => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLLIElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const searchIconRef = useRef<HTMLButtonElement | null>(null);

  const handleClickOutside = (e: MouseEvent): void => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };
  const handlePressEsc = (e: KeyboardEvent): void => {
    if (e.key === 'Escape') {
      setIsOpen(false);
      dropdownRef.current.blur();
    }
  };

  useEffect(() => {
    const cleanUp = () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handlePressEsc);
    };

    if (!isOpen) return cleanUp;

    document.addEventListener('keydown', handlePressEsc);
    document.addEventListener('click', handleClickOutside);

    return cleanUp;
  }, [isOpen]);

  useEffect(() => {
    if (!isSearchOpen) return;

    searchInputRef.current.focus();

    const handleClickOutsideSearch = (e: MouseEvent): void => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(e.target as Node) &&
        !searchIconRef.current.contains(e.target as Node)
      ) {
        setIsSearchOpen(false);
      }
    };
    const handlePressEsc = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutsideSearch);
    document.addEventListener('keydown', handlePressEsc);

    return () => {
      document.removeEventListener('click', handleClickOutsideSearch);
      document.removeEventListener('keydown', handlePressEsc);
    };
  }, [isSearchOpen]);

  return {
    searchIconRef,
    setIsOpen,
    dropdownRef,
    isOpen,
    isSearchOpen,
    searchInputRef,
    setIsSearchOpen,
  };
};
