import dynamic from 'next/dynamic';

// const CookieBanner = dynamic(() => import('@atoms/CookieBanner'));
const Footer = dynamic(() => import('@organisms/Footer'));

import Navigation from '@organisms/Navigation';
import type { ReactElement } from 'react';

import { GlobalStyle, ThemeProvider } from '@/styles';

import { SkipButton } from './Layout.styled';
import type { LayoutProps } from './Layout.types';

export const Layout = ({
  children,
  navigation,
  footer,
}: LayoutProps): ReactElement => (
  <ThemeProvider>
    <GlobalStyle />
    <SkipButton as="a" href="#mainContent">
      Przejdź do treści
    </SkipButton>
    <SkipButton as="a" href="#desktopSearchButton">
      Przejdź do wyszukiwania
    </SkipButton>
    <Navigation navigationItems={navigation?.navigationItems} />
    <div id="mainContent">{children}</div>
    {/*<CookieBanner />*/}
    <Footer
      footerLinks={footer?.footerLinks}
      additionalLinks={footer?.additionalLinks}
    />
  </ThemeProvider>
);
