import styled from 'styled-components';

export const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.palette.lightGray};
  height: 46px;
  border-radius: 6px;
  min-width: 240px;
  padding-left: 10px;
  outline-color: ${({ theme }) => theme.palette.secondary};
  outline-offset: 4px;
  max-width: calc(100vw - 10px);

  &::placeholder {
    font-size: 14px;
  }

  &:invalid {
    border-color: ${({ theme }) => theme.palette.primary};
  }
`;
