import Button from '@atoms/Button';
import styled from 'styled-components';

import { button } from '@/styles/shared';

export const SkipButton = styled(Button)`
  ${button};
  display: block;
  opacity: 0;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: -1;
  height: 40px;

  &:focus,
  &:focus-within {
    opacity: 1;
    z-index: 100;
  }
`;
