import type { ReactElement } from 'react';

import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import type { NavigationProps } from './Navgiation.types';

export const Navigation = ({
  navigationItems,
}: NavigationProps): ReactElement => {
  if (!navigationItems) return null;

  return (
    <>
      <DesktopNavigation navigationItems={navigationItems} />
      <MobileNavigation navigationItems={navigationItems} />
    </>
  );
};
