export enum INPUT_TYPE {
  EMAIL = 'email',
  TEXT = 'text',
  TEXTAREA = 'textarea',
}

export interface InputProps {
  id?: string;
  name: string;
  placeholder: string;
  type: INPUT_TYPE;
}
