import type { ImageLoader } from 'next/image';
import NextImage from 'next/image';
import type { ReactElement } from 'react';

import type { ImageProps } from './Image.types';
import { builder } from './utils';

export const Image = ({
  src,
  alt,
  width,
  height,
  fill,
  quality,
  priority,
  loading,
  placeholder,
  blurDataURL,
  unoptimized,
  onLoadingComplete,
  sizes,
  style,
  rounded = true,
}: ImageProps): ReactElement => {
  if (!String(src).includes('https://cdn.sanity.io/')) {
    return <img src={String(src)} alt={alt} />;
  }

  const imageUrlFromBuilder = builder.image(src);
  const urlFor = source => builder.image(source);
  const loader: ImageLoader = ({ width, quality }) => {
    switch (true) {
      case !!height && !!width:
      case !!width:
        return (
          urlFor(src)
            .width(width)
            .quality(quality || 75)
            .auto('format')
            .url() || ''
        );
      default:
        return (
          urlFor(src)
            .quality(quality || 75)
            .auto('format')
            .url() || ''
        );
    }
  };

  return (
    <NextImage
      src={imageUrlFromBuilder.url()}
      alt={alt ?? ''}
      width={width}
      height={height}
      fill={fill}
      quality={quality}
      priority={priority}
      loading={loading}
      placeholder={placeholder}
      blurDataURL={blurDataURL}
      unoptimized={unoptimized}
      onLoadingComplete={onLoadingComplete}
      style={rounded ? { borderRadius: '6px', ...style } : { ...style }}
      sizes={sizes}
      loader={loader}
    />
  );
};
