import { NavigateBefore } from '@styled-icons/material-rounded/NavigateBefore';
import styled from 'styled-components';

import { button } from '@/styles/shared';

export const DropdownWrapper = styled.div`
  position: relative;
  z-index: 20;
`;

export const NavigationButton = styled.button`
  position: relative;
  background: none;
  letter-spacing: unset;
  border-radius: unset;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 3px;

  &:hover {
    box-shadow: none;
  }
`;

export const DropdownList = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 35px;
  left: -37px;
  width: 350px;
  padding: 20px;
  background: white;
  border-radius: 6px;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  flex-direction: column;

  a {
    margin: 0;
    color: ${({ theme }) => theme.palette.dark};
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 500;

    :hover {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const DropdownItemsWrapper = styled.ul`
  display: grid;
  grid-template-columns: 150px 150px;

  li {
    text-align: center;
    margin-bottom: 14px;
  }
`;

export const IconWrapper = styled.span<{ dropdownIsOpen: boolean }>`
  transform: ${({ dropdownIsOpen }) =>
    dropdownIsOpen ? 'rotate(90deg)' : 'rotate(270deg)'};
  transition: all 0.3s ease;
  width: 18px;
  height: 18px;
  margin-left: 2px;
  display: flex;
  align-items: center;
`;

export const DropdownIcon = styled(NavigateBefore)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.palette.dark};
  position: relative;
`;

export const MainLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  width: 100%;
  color: white;

  a {
    ${button};
    padding: 11px 25px;
    font-size: 14px;
    margin: 10px 0 0 0;
    color: white !important;

    :hover {
      color: white;
    }
  }
`;
