import type { ReactElement, ReactNode } from 'react';
import { ThemeProvider as ThemeProviderBase } from 'styled-components';

import theme from './theme';

interface ThemeProviderProps {
  children?: ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps): ReactElement => (
  <ThemeProviderBase theme={theme}>{children}</ThemeProviderBase>
);

export default ThemeProvider;
