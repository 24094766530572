import { apiVersion, dataset, projectId, useCdn } from '@sanity/env';
import { createClient } from 'next-sanity';

export const client = createClient({
  apiVersion,
  dataset,
  projectId,
  useCdn,
});

export const previewClient = createClient({
  apiVersion,
  dataset,
  projectId,
  useCdn: false,
  token: process.env.SANITY_PREVIEW_TOKEN,
});
