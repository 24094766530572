import { css } from 'styled-components';

export const gradient = 'linear-gradient(128deg, #ff5858 7%, #f09819 100%)';

export const outline = css`
  outline: 1px solid ${({ theme }) => theme.palette.primary};
  outline-offset: 3px;
`;

export const boxShadow = '0px 0px 17px -3px #FF5858';

export const cardBackround = css`
  border-radius: 6px;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.lightGray};
`;

export const button = css`
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 700;
  background: ${gradient};
  color: white;
  border-radius: 40px;
  padding: 11px 34px;
  font-size: 16px;
  border: none;
  letter-spacing: 0.5px;
  outline-color: ${({ theme }) => theme.palette.primary};
  outline-offset: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    box-shadow: ${boxShadow};
    transition: all 0.2s ease;
  }
`;
