import styled, { css } from 'styled-components';

import type { ButtonProps } from './Button.types';
import { SIZE } from './Button.types';

export const Button = styled.button<Partial<ButtonProps>>`
  ${({ size }) =>
    size === SIZE.SMALL &&
    css`
      padding: 7px 23px;
    `};
`;
