import Link from 'next/link';
import type { ReactElement } from 'react';

import type { DropdownItemProps } from '../Navgiation.types';
import {
  DropdownIcon,
  DropdownItemsWrapper,
  DropdownList,
  DropdownWrapper,
  IconWrapper,
  MainLinkWrapper,
  NavigationButton,
} from './DropdownItem.styled';

export const DropdownItem = ({
  title,
  linkItems,
  mainLink,
  isOpen: dropdownIsOpen,
  setIsOpen,
}: DropdownItemProps): ReactElement => (
  <DropdownWrapper>
    <NavigationButton onClick={() => setIsOpen(prevState => !prevState)}>
      {title}
      <IconWrapper dropdownIsOpen={dropdownIsOpen}>
        <DropdownIcon />
      </IconWrapper>
    </NavigationButton>
    <DropdownList isOpen={dropdownIsOpen}>
      <DropdownItemsWrapper>
        {linkItems.map(({ link, title }) => (
          <li key={`linkItem-${link}`}>
            <Link href={link}>{title}</Link>
          </li>
        ))}
      </DropdownItemsWrapper>
      {mainLink && (
        <MainLinkWrapper>
          <Link href={mainLink.link}>{mainLink.title}</Link>
        </MainLinkWrapper>
      )}
    </DropdownList>
  </DropdownWrapper>
);
