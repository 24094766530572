import { Search } from '@styled-icons/boxicons-regular/Search';
import styled from 'styled-components';

export const NavWrapper = styled.nav`
  display: none;
  justify-content: flex-end;
  align-items: center;
  background: white;
  padding: 10px 30px;
  position: relative;

  @media ${({ theme }) => theme.media.desktop} {
    display: flex;
  }
`;

export const LogoWrapper = styled.div`
  max-width: 236px;
  height: 46px;
  width: auto;
  position: absolute;
  left: 20px;

  img {
    width: 100%;
    height: 100%;
    padding: 4px 0;
  }
`;

export const NavigationItems = styled.ul`
  display: flex;
  align-items: center;
`;

export const ItemWrapper = styled.li`
  button {
    margin-right: 14px;

    @media ${({ theme }) => theme.media.largeDesktop} {
      margin-right: 22px;
    }
  }

  > a {
    color: ${({ theme }) => theme.palette.dark};
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    margin-right: 22px;
    padding: 3px 0;

    :hover {
      color: ${({ theme }) => theme.palette.primary};
    }

    @media ${({ theme }) => theme.media.largeDesktop} {
      margin-right: 30px;
    }
  }
`;

export const SearchIcon = styled(Search)`
  width: 22px;
  height: 22px;
  color: ${({ theme }) => theme.palette.dark};
`;

export const SearchIconWrapper = styled.button`
  display: flex;
  align-items: center;
  position: relative;

  span {
    font-size: 14px;
    margin-left: 5px;
  }
`;

export const Separator = styled.div`
  background: ${({ theme }) => theme.palette.lightGray};
  height: 40px;
  width: 1px;
  margin-right: 15px;
`;

export const SearchInputWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 54px;
`;

export const NavigationContainer = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.desktop} {
    display: block;
  }
`;
